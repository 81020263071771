import Vue from "vue";
import _ from "lodash";
import axios from "@/http/axios";
import productsModule from "@/store/modules/products";
import { getNested } from "@/store/utils";

// Need to to a deep clone because otherwise we will share the same state.
const clonedState = _.cloneDeep(productsModule.state);
const state = {
  ...clonedState,
  constituents: {},
  exposureIndices: {},
  actions: {},
  relativeDrawdowns: {},
  diffMonthlyCumulativeReturns: {},
  topConsituentsWeight: {},
  indicators: {},
  vegaConditions: {},
  vixPremium: {},
};

const getters = {
  ...productsModule.getters,
  getExposureIndices:
    (state) =>
    ({ mode }) => {
      return getNested(state.exposureIndices, mode);
    },
  getConstituents:
    (state) =>
    ({ mode }) => {
      return getNested(state.constituents, mode);
    },
  getActions:
    (state) =>
    ({ mode }) => {
      return getNested(state.actions, mode);
    },
  getRelativeDrawdowns:
    (state) =>
    ({ mode }) => {
      return getNested(state.relativeDrawdowns, mode);
    },
  getTopConstituentsWeight:
    (state) =>
    ({ mode }) => {
      return getNested(state.topConsituentsWeight, mode);
    },
  getIndicators:
    (state) =>
    ({ mode }) => {
      return getNested(state.indicators, mode);
    },
  getVegaConditions:
    (state) =>
    ({ mode }) => {
      return getNested(state.vegaConditions, mode);
    },
  getVixPremium:
    (state) =>
    ({ mode }) => {
      return getNested(state.vixPremium, mode);
    },
  getDiffMonthlyCumulativeReturns:
    (state) =>
    ({ mode }) => {
      return getNested(state.diffMonthlyCumulativeReturns, mode);
    },
};

const mutations = {
  ...productsModule.mutations,
  FETCH_EXPOSURE_INDICES_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.exposureIndices, mode, data);
  },
  FETCH_CONSTITUENTS_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.constituents, mode, data);
  },
  FETCH_TIMELINE_ACTIONS_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.actions, mode, data);
  },
  FETCH_RELATIVE_DRAWDOWNS_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.relativeDrawdowns, mode, data);
  },
  FETCH_INDICATORS_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.indicators, mode, data);
  },
  FETCH_TOP_CONSTITUENTS_WEIGHT_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.topConsituentsWeight, mode, data);
  },
  FETCH_VEGA_CONDITIONS_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.vegaConditions, mode, data);
  },
  FETCH_VIX_PREMIUM_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.vixPremium, mode, data);
  },
  FETCH_DIFF_MONTHLY_CUMULATIVE_RETURNS_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.diffMonthlyCumulativeReturns, mode, data);
  },
};

const actions = {
  ...productsModule.actions,
  async fetchExposureIndices({ commit }, { productId, mode, period }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/exposure-indices`,
        {
          params: params,
        }
      );
      commit("FETCH_EXPOSURE_INDICES_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_EXPOSURE_INDICES_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchConstituents({ commit }, { productId, mode }) {
    const params = {};
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/constituents`,
        {
          params: params,
        }
      );
      commit("FETCH_CONSTITUENTS_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_CONSTITUENTS_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchActions({ commit }, { productId, mode, period }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    try {
      const response = await axios.get(`/api/products/${productId}/actions`, {
        params: params,
      });
      const actions = response.data;
      let groupedActions = actions.reduce((dateToActions, action) => {
        const date = action.date;
        if (!dateToActions[date]) {
          dateToActions[date] = [];
        }
        const actionLowerCase = Object.fromEntries(
          Object.entries(action).map(([k, v]) => [k.toLowerCase(), v])
        );
        dateToActions[date].push(actionLowerCase);
        return dateToActions;
      }, {});
      groupedActions = Object.values(groupedActions).sort((a, b) =>
        a[0].date == b[0].date
          ? a[0].action.localCompare(b[0].action)
          : a[0].date > b[0].date
          ? -1
          : 1
      );
      const data = [];
      groupedActions.forEach((groupedAction) => {
        Object.values(
          groupedAction.reduce((temp, item) => {
            if (!temp[item.action]) {
              temp[item.action] = [];
            }
            temp[item.action].push(item);
            return temp;
          }, {})
        ).forEach((actions) => {
          data.push({
            id: actions[0].date + actions[0].action,
            color: "primary",
            icon: "RefreshCwIcon",
            title: actions[0].action,
            time: new Date(Date.parse(actions[0].date)).toLocaleString(
              "default",
              {
                year: "numeric",
                month: "short",
                day: "2-digit",
              }
            ),
            positions: actions[0].name == null ? null : actions,
          });
        });
      });
      commit("FETCH_TIMELINE_ACTIONS_SUCCESS", { mode, data });
    } catch (error) {
      commit("FETCH_TIMELINE_ACTIONS_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchRelativeDrawdowns(
    { commit },
    { productId, mode, period, benchmarkSymbol }
  ) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (benchmarkSymbol !== undefined) {
      params["benchmark_symbol"] = benchmarkSymbol;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/relative-drawdowns`,
        {
          params: params,
        }
      );
      commit("FETCH_RELATIVE_DRAWDOWNS_SUCCESS", {
        mode,
        benchmarkSymbol,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_RELATIVE_DRAWDOWNS_SUCCESS", {
        mode,
        benchmarkSymbol,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchTopConstituentsWeight({ commit }, { productId, mode, period }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/top-constituents-weight`,
        {
          params: params,
        }
      );
      commit("FETCH_TOP_CONSTITUENTS_WEIGHT_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_TOP_CONSTITUENTS_WEIGHT_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchIndicators({ commit }, { productId, mode, period }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/indicators`,
        {
          params: params,
        }
      );
      commit("FETCH_INDICATORS_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_INDICATORS_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchVegaConditions({ commit }, { productId, mode, period }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/vega-conditions`,
        {
          params: params,
        }
      );
      commit("FETCH_VEGA_CONDITIONS_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_VEGA_CONDITIONS_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchVixPremium({ commit }, { productId, mode, period }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/vix-premium`,
        {
          params: params,
        }
      );
      commit("FETCH_VIX_PREMIUM_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_VIX_PREMIUM_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchDiffMonthlyCumulativeReturns(
    { commit },
    { productId, mode, benchmarkSymbol }
  ) {
    const params = {};
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (benchmarkSymbol !== undefined) {
      params["benchmark_symbol"] = benchmarkSymbol;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/diff-monthly-cumulative-returns`,
        { params: params }
      );
      commit("FETCH_DIFF_MONTHLY_CUMULATIVE_RETURNS_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_DIFF_MONTHLY_CUMULATIVE_RETURNS_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
