import Vue from "vue";
import _ from "lodash";
import axios from "@/http/axios";
import productsModule from "@/store/modules/products";
import { getNested } from "@/store/utils";

// Need to to a deep clone because otherwise we will share the same state.
const clonedState = _.cloneDeep(productsModule.state);
const state = {
  ...clonedState,
  exposureIndices: {},
  recentDataOverview: {},
  vixPremium: {},
  indicators: {},
  vegaWithBetSizing: {},
  vegaConditions: {},
};

const getters = {
  ...productsModule.getters,
  getExposureIndices:
    (state) =>
    ({ mode }) => {
      return getNested(state.exposureIndices, mode);
    },
  getRecentDataOverview:
    (state) =>
    ({ mode }) => {
      return getNested(state.recentDataOverview, mode);
    },
  getVixPremium:
    (state) =>
    ({ mode }) => {
      return getNested(state.vixPremium, mode);
    },
  getIndicators:
    (state) =>
    ({ mode }) => {
      return getNested(state.indicators, mode);
    },
  getVegaWithBetSizing:
    (state) =>
    ({ mode }) => {
      return getNested(state.vegaWithBetSizing, mode);
    },
  getVegaConditions:
    (state) =>
    ({ mode }) => {
      return getNested(state.vegaConditions, mode);
    },
};

const mutations = {
  ...productsModule.mutations,
  FETCH_EXPOSURE_INDICES_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.exposureIndices, mode, data);
  },
  FETCH_RECENT_DATA_OVERVIEW_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.recentDataOverview, mode, data);
  },
  FETCH_VIX_PREMIUM_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.vixPremium, mode, data);
  },
  FETCH_INDICATORS_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.indicators, mode, data);
  },
  FETCH_VEGA_WITH_BET_SIZING_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.vegaWithBetSizing, mode, data);
  },
  FETCH_VEGA_CONDITIONS_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.vegaConditions, mode, data);
  },
};

const actions = {
  ...productsModule.actions,
  async fetchExposureIndices({ commit }, { productId, mode, period }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/exposure-indices`,
        {
          params: params,
        }
      );
      commit("FETCH_EXPOSURE_INDICES_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_EXPOSURE_INDICES_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchRecentDataOverview({ commit }, { productId, mode }) {
    const params = {};
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/recent-data-overview`,
        {
          params: params,
        }
      );
      commit("FETCH_RECENT_DATA_OVERVIEW_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_RECENT_DATA_OVERVIEW_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchVixPremium({ commit }, { productId, mode, period }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/vix-premium`,
        {
          params: params,
        }
      );
      commit("FETCH_VIX_PREMIUM_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_VIX_PREMIUM_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchIndicators({ commit }, { productId, mode, period }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/indicators`,
        {
          params: params,
        }
      );
      commit("FETCH_INDICATORS_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_INDICATORS_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchVegaWithBetSizing({ commit }, { productId, mode, period }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/vega-with-bet-sizing`,
        {
          params: params,
        }
      );
      commit("FETCH_VEGA_WITH_BET_SIZING_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_VEGA_WITH_BET_SIZING_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchVegaConditions({ commit }, { productId, mode, period }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/vega-conditions`,
        {
          params: params,
        }
      );
      commit("FETCH_VEGA_CONDITIONS_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_VEGA_CONDITIONS_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
