import Vue from "vue";
import _ from "lodash";
import axios from "@/http/axios";
import productsModule from "@/store/modules/products";
import { getNested } from "@/store/utils";

// Need to to a deep clone because otherwise we will share the same state.
const clonedState = _.cloneDeep(productsModule.state);
const state = {
  ...clonedState,
  useCaseDetails: {},
  ratioVixFutures: {},
  recentDataOverview: {},
  vixPremium: {},
  // Use case
  useCaseCumulativeReturns: {},
  useCasePerformanceData: {},
  useCaseDrawdowns: {},
  useCaseBiggestDrawdowns: {},
};

const getters = {
  ...productsModule.getters,
  getUseCaseDetails:
    (state) =>
    ({ mode }) => {
      return getNested(state.useCaseDetails, mode);
    },
  getRatioVixFutures:
    (state) =>
    ({ mode }) => {
      return getNested(state.ratioVixFutures, mode);
    },
  getRecentDataOverview:
    (state) =>
    ({ mode }) => {
      return getNested(state.recentDataOverview, mode);
    },
  getVixPremium:
    (state) =>
    ({ mode }) => {
      return getNested(state.vixPremium, mode);
    },
  // Use case
  getUseCaseCumulativeReturns:
    (state) =>
    ({ mode, benchmark, weight }) => {
      return getNested(state.useCaseCumulativeReturns, mode, benchmark, weight);
    },
  getUseCasePerformanceData:
    (state) =>
    ({ mode, benchmark, weight }) => {
      return getNested(state.useCasePerformanceData, mode, benchmark, weight);
    },
  getUseCaseDrawdowns:
    (state) =>
    ({ mode, benchmark, weight }) => {
      return getNested(state.useCaseDrawdowns, mode, benchmark, weight);
    },
  getUseCaseBiggestDrawdowns:
    (state) =>
    ({ mode, benchmark, weight }) => {
      return getNested(state.useCaseBiggestDrawdowns, mode, benchmark, weight);
    },
};

const mutations = {
  ...productsModule.mutations,
  FETCH_USE_CASE_DETAILS_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.useCaseDetails, mode, data);
  },
  FETCH_RATIO_VIX_FUTURES: (state, { mode, data }) => {
    Vue.set(state.ratioVixFutures, mode, data);
  },
  FETCH_RECENT_DATA_OVERVIEW_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.recentDataOverview, mode, data);
  },
  FETCH_VIX_PREMIUM_SUCCESS: (state, { mode, data }) => {
    Vue.set(state.vixPremium, mode, data);
  },
  // Use case
  FETCH_USE_CASE_CUMULATIVE_RETURNS_SUCCESS: (
    state,
    { mode, benchmark, weight, data }
  ) => {
    const newWeightObj =
      getNested(state.useCaseCumulativeReturns, mode, benchmark) || {};
    Vue.set(newWeightObj, weight, data);
    const newBenchmarkObj =
      getNested(state.useCaseCumulativeReturns, mode) || {};
    Vue.set(newBenchmarkObj, benchmark, newWeightObj);
    Vue.set(state.useCaseCumulativeReturns, mode, newBenchmarkObj);
  },
  FETCH_USE_CASE_PERFORMANCE_DATA_SUCCESS: (
    state,
    { mode, benchmark, weight, data }
  ) => {
    const newWeightObj =
      getNested(state.useCasePerformanceData, mode, benchmark) || {};
    Vue.set(newWeightObj, weight, data);
    const newBenchmarkObj = getNested(state.useCasePerformanceData, mode) || {};
    Vue.set(newBenchmarkObj, benchmark, newWeightObj);
    Vue.set(state.useCasePerformanceData, mode, newBenchmarkObj);
  },
  FETCH_USE_CASE_DRAWDOWNS_SUCCESS: (
    state,
    { mode, benchmark, weight, data }
  ) => {
    const newWeightObj =
      getNested(state.useCaseDrawdowns, mode, benchmark) || {};
    Vue.set(newWeightObj, weight, data);
    const newBenchmarkObj = getNested(state.useCaseDrawdowns, mode) || {};
    Vue.set(newBenchmarkObj, benchmark, newWeightObj);
    Vue.set(state.useCaseDrawdowns, mode, newBenchmarkObj);
  },
  FETCH_USE_CASE_BIGGEST_DRAWDOWNS_SUCCESS: (
    state,
    { mode, benchmark, weight, data }
  ) => {
    const newWeightObj =
      getNested(state.useCaseBiggestDrawdowns, mode, benchmark) || {};
    Vue.set(newWeightObj, weight, data);
    const newBenchmarkObj =
      getNested(state.useCaseBiggestDrawdowns, mode) || {};
    Vue.set(newBenchmarkObj, benchmark, newWeightObj);
    Vue.set(state.useCaseBiggestDrawdowns, mode, newBenchmarkObj);
  },
};

const actions = {
  ...productsModule.actions,
  async fetchUseCaseDetails({ commit }, { productId, mode, benchmarkId }) {
    const params = {};
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (benchmarkId !== undefined) {
      params["benchmark_id"] = benchmarkId;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/use-case/details`,
        {
          params: params,
        }
      );
      commit("FETCH_USE_CASE_DETAILS_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_USE_CASE_DETAILS_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchRatioVixFutures({ commit }, { productId, mode, period }) {
    const params = {};
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/ratio-vix-futures`,
        {
          params: params,
        }
      );
      commit("FETCH_RATIO_VIX_FUTURES", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_RATIO_VIX_FUTURES", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchRecentDataOverview({ commit }, { productId, mode }) {
    const params = {};
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/recent-data-overview`,
        {
          params: params,
        }
      );
      commit("FETCH_RECENT_DATA_OVERVIEW_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_RECENT_DATA_OVERVIEW_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchVixPremium({ commit }, { productId, mode, period }) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/vix-premium`,
        {
          params: params,
        }
      );
      commit("FETCH_VIX_PREMIUM_SUCCESS", {
        mode,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_VIX_PREMIUM_SUCCESS", {
        mode,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  // Use case
  async fetchUseCaseCumulativeReturns(
    { commit },
    { productId, mode, period, benchmark, weight }
  ) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (benchmark !== undefined) {
      params["benchmark"] = benchmark;
    }
    if (weight !== undefined) {
      params["weight"] = weight;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/use-case/cumulative-returns`,
        {
          params: params,
        }
      );
      commit("FETCH_USE_CASE_CUMULATIVE_RETURNS_SUCCESS", {
        mode,
        benchmark,
        weight,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_USE_CASE_CUMULATIVE_RETURNS_SUCCESS", {
        mode,
        benchmark,
        weight,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchUseCasePerformanceData(
    { commit },
    { productId, mode, period, benchmark, weight, statIds }
  ) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (benchmark !== undefined) {
      params["benchmark"] = benchmark;
    }
    if (weight !== undefined) {
      params["weight"] = weight;
    }
    if (statIds !== undefined) {
      params["stat_ids"] = statIds;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/use-case/performance-data`,
        {
          params: params,
        }
      );
      commit("FETCH_USE_CASE_PERFORMANCE_DATA_SUCCESS", {
        mode,
        benchmark,
        weight,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_USE_CASE_PERFORMANCE_DATA_SUCCESS", {
        mode,
        benchmark,
        weight,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchUseCaseDrawdowns(
    { commit },
    { productId, mode, period, benchmark, weight }
  ) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (benchmark !== undefined) {
      params["benchmark"] = benchmark;
    }
    if (weight !== undefined) {
      params["weight"] = weight;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/use-case/drawdowns`,
        {
          params: params,
        }
      );
      commit("FETCH_USE_CASE_DRAWDOWNS_SUCCESS", {
        mode,
        benchmark,
        weight,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_USE_CASE_DRAWDOWNS_SUCCESS", {
        mode,
        benchmark,
        weight,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
  async fetchUseCaseBiggestDrawdowns(
    { commit },
    { productId, mode, period, benchmark, weight }
  ) {
    const params = {};
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (mode !== undefined) {
      params["mode"] = mode;
    }
    if (benchmark !== undefined) {
      params["benchmark"] = benchmark;
    }
    if (weight !== undefined) {
      params["weight"] = weight;
    }
    try {
      const response = await axios.get(
        `/api/products/${productId}/use-case/biggest-drawdowns`,
        {
          params: params,
        }
      );
      commit("FETCH_USE_CASE_BIGGEST_DRAWDOWNS_SUCCESS", {
        mode,
        benchmark,
        weight,
        data: response.data,
      });
    } catch (error) {
      commit("FETCH_USE_CASE_BIGGEST_DRAWDOWNS_SUCCESS", {
        mode,
        benchmark,
        weight,
        data: null,
      });
      // We rethrow the error for sentry to catch it.
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
